<template>
  <div class="pagination">
    <div class="pagination__left">
      Rows count: {{rowCount}} showing {{ this.fromTo() }}
    </div>
    <div class="pagination__right">
      <div>View</div>
      <select @change="onChange($event)">
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
      </select>
      <a @click="clickOnFirst" v-if="currentPageNumber !== 1">1</a>
      <a @click="clickOnPrev" v-if="currentPageNumber !== 1">Prev</a>
      <div>Current page: {{currentPageNumber}}</div>
      <a @click="clickOnNext" v-if="currentPageNumber !== pageCount">Next</a>
      <a @click="clickOnLast" v-if="currentPageNumber !== pageCount">{{pageCount}}</a>
    </div>
  </div>
</template>

<script>
import './Pagination.styl'

export default {
    props: ['pageCount', 'rowCount'],
    name: 'Pagination',
    data: () => ({
      currentPageNumber: 1,
      currentPageSize: 50,
    }),
    mounted () {
      this.showPage();
    },
    methods: {
      fromTo () {
        const from = (this.currentPageNumber - 1) * this.currentPageSize + 1;
        const remainingRows = Number(this.currentPageSize) - 1;
        let to = from + remainingRows;
        if (this.currentPageNumber === this.pageCount) {
          to = this.rowCount;
        }
        return from + ' - ' + to;
      },
      onChange (event) {
        this.currentPageSize = event.target.value;
        this.clickOnFirst();
      },
      clickOnFirst () {
        this.currentPageNumber = 1;
        this.showPage();
      },
      clickOnPrev () {
        this.currentPageNumber = this.currentPageNumber - 1;
        this.showPage();
      },
      clickOnNext () {
        this.currentPageNumber = this.currentPageNumber + 1;
        this.showPage();
      },
      clickOnLast () {
        this.currentPageNumber = this.pageCount;
        this.showPage();
      },
      scrollToTop () {
        document.querySelectorAll('.networkDashboard__table-body > .__vuescroll > .__panel').forEach(scrollWrapp => scrollWrapp.scrollTo({ top: 0, left: 0, behavior: 'instant' }));
      },
      showPage () {
        const data = {
          pageNumber: this.currentPageNumber,
          pageSize: this.currentPageSize,
        }
        this.$emit('pageClick', data);
        this.scrollToTop();
      },
    },
}
</script>
